import {ActivatedRoute, ResolveFn, Router} from '@angular/router';
import {ISidebarItem} from '../../../oex-ui-kit/components/sidebar/sidebar.component';
import {inject} from '@angular/core';
import {AuthService} from '../authService';
import {ELITE_TIER_ID} from '../../pages/company/my-company';
import {PortalService} from '../../portal/services/portal-service';
import {APIService} from '../api.service';
import {ICompany, IPortalCounters} from '../api.types';

export const pdSidebarItems: ResolveFn<ISidebarItem[]> = async () => {
  const api = inject(APIService);
  const auth = inject(AuthService);
  const ps = inject(PortalService);
  const route = inject(ActivatedRoute);
  const router = inject(Router);
  const items: ISidebarItem[] = [
    {label: 'Overview', url: '/portal', exact: true}
  ];
  let counters: IPortalCounters|undefined;
  const nws = ps.getViewCompanyNWS(route);
  const companies = auth.getUserCompanies();
  let companyData: ICompany | undefined;
  if (nws || companies[0]) {
    const reqNws = nws || companies[0].nws;
    const p1 = api.getCompany(reqNws)
    const p2 = api.getPortalCountersForPD(reqNws);
    let resp: any;
    try {
      resp = await Promise.all([p1, p2]);
    } catch {}
    companyData = resp[0];
    counters = resp[1];
  }

  /*
   const viewCompanyUrl = nws ? `/view-company/${nws}` : '';
   items.push({
     label: '',
     image: '/assets/img/partner-portal-logo.svg',
     url: (companies.length || nws) ? (viewCompanyUrl + '/portal') : (viewCompanyUrl + '/portal/company/')
   });
   */

  // "Enroll as a Partner"
  if (!companies[0] && !nws) {
    items.push({label: 'Enroll as a Partner', url: '/portal/company/'});
    items.push({label: 'Join a Partner', url: '/portal/join/'});
  }
  // items.push({label: 'Join a Partner', url: '/portal/join/'});
  if (companyData?.first_approval_date) {
    items.push({
      label: 'Partner Benefits', children: [
        {label: 'Benefits overview', exact: true, url: 'benefits'},
        ...(companyData?.tier ? [
          {label: 'Software', url: 'benefits/intersystems'},
          {label: 'Training', url: 'benefits/training'},
          {label: 'Support', url: 'benefits/support'},
          {label: 'Marketing', url: 'benefits/marketing'},
          {label: 'Benefits Reports ', url: 'benefits/reports'},
        ] : [])
      ]
    });
  }

  const pd = {label: 'Partner Directory', children: []} as ISidebarItem;

  // const companies = [{nws: 'test33', name: 'Test 33'}, {nws: 'test22', name: 'Test test'}];
  if ((companies.length <= 1 && companies[0]) || (nws)) {
    pd.children?.push({label: 'Company Profile', url: `company/${nws || companies[0].nws}`});
  } else {
    if (companies.length > 1) {
      const children: ISidebarItem[] = companies.map(c => {
        return {
          label: c.name,
          url: 'company/' + c.nws
        } as ISidebarItem;
      });
      pd.children?.push({label: 'Companies', children});
    }
  }

  // Partners Directory
  if (companyData?.first_approval_date) {
    pd.children?.push({label: 'Products', url: 'products', counter: (counters?.products || 0).toString()});
    // pd.children.push({label: 'Statistics', url: 'statistics'});
    pd.children?.push({label: 'Services', url: 'services', counter: (counters?.services || 0).toString()});
    if (companyData?.tier === ELITE_TIER_ID) {
      pd.children?.push({label: 'Success Stories', url: 'stories'});
    }
  }
  pd.children?.push({label: 'Reviews', url: 'reviews', counter: (counters?.reviews || 0).toString()});
  pd.children?.push({label: 'Messages', url: 'messages', counter: (counters?.messages || 0).toString()});
  if (companyData?.first_approval_date) {
    pd.children?.push({
      label: 'View my profile',
      url: `/company/${nws || companies[0].nws}`,
      icon: '/assets/img/icon-link-white.svg',
      style: 'link-underline'
    });
  }
  items.push(pd);
  if (counters?.inquiries) {
    items.push({
      label: 'Health Innovation Hub Inquiries',
      url: 'inquiries',
      counter: (counters?.inquiries || 0).toString()
    });
  }
  if (companies[0] || nws) {
    items.push({label: 'Members', url: 'members'});
  }
  if (companyData?.first_approval_date) {
    items.push({label: 'Get Certified', url: 'certification'});
    items.push({label: 'Marketing Tools', url: 'marketing-tools'});
    items.push({label: 'Events', url: 'events'});
    items.push({label: 'Startup Program', url: 'startup'});
  }
  const profileItems: ISidebarItem[] = [
    {label: 'Personal data', url: 'profile/personal'},
    // {label: 'Subscriptions', url: 'profile/subscriptions'},
    // {label: 'Ecosystem services', url: 'profile/ecosystem-services'},
  ];
  if (auth.isAdmin) {
    profileItems.push({label: 'Admin panel', url: '/admin'});
  }
  // profileItems.push({label: 'Log out', click: () => this.logOut()} as ISidebarItem);
  items.push({
    label: 'My Profile', children: profileItems
  });

  // Open Exchange
  /*items.push({label: 'Open Exchange', children: [
      {label: 'Applications', urlExt: 'https://openexchange.intersystems.com/portal/products'},
      {label: 'Go to my OEx profile', urlExt: `https://openexchange.intersystems.com/user/${this.auth.getUserName()}/${this.auth.getUserKey()}`, icon: '/assets/img/icon-link-white.svg', link: true}
  ]});*/

  items.push({label: 'Contact InterSystems', url: 'contacts'});

  return items;
}
