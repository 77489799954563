import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges
} from '@angular/core';
import {RouterLink} from "@angular/router";

interface IExternalHeaderItem {
  text: string;
  url: string;
}

@Component({
  selector: 'ui-external-header',
  standalone: true,
  imports: [
    RouterLink
  ],
  templateUrl: './external-header.component.html',
  styleUrl: './external-header.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExternalHeaderComponent implements OnInit, OnChanges {
  @Input() adminRouterLink = '';
  @Input() currentSiteName = '';
  @Input() iconClass = '';
  isSiteExist = false;
  items: IExternalHeaderItem[] = [
    {
      text: 'Learning',
      url: 'https://learning.intersystems.com/'
    },
    {
      text: 'Documentation',
      url: 'https://docs.intersystems.com/'
    },
    {
      text: 'Community',
      url: 'https://community.intersystems.com/'
    },
    {
      text: 'Global Masters',
      url: 'https://globalmasters.intersystems.com/'
    },
    {
      text: 'Certification',
      url: 'https://www.intersystems.com/support-learning/learning-services/certification-program/'
    },
    {
      text: 'Open Exchange',
      url: 'https://openexchange.intersystems.com/'
    },
    {
      text: 'Partner Directory',
      url: 'https://partner.intersystems.com/'
    },
    {
      text: 'Startup Hub',
      url: 'https://startup.intersystems.com'
    },
    {
      text: 'Ideas Portal',
      url: 'https://ideas.intersystems.com/'
    }/*,
    {
      text: 'Health Innovation Hub',
      url: 'https://partner.intersystems.com/health-innovation-hub/'
    }*/
  ];

  constructor(private cdr: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.checkSiteExists();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['currentSiteName'].currentValue && changes['currentSiteName'].currentValue !== changes['currentSiteName'].previousValue) {
      this.checkSiteExists();
      this.cdr.detectChanges();
    }
  }

  private checkSiteExists() {
    this.isSiteExist = this.items.some(el => el.text === this.currentSiteName);
  }
}
