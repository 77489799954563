import {APP_INITIALIZER, ApplicationConfig, importProvidersFrom, PLATFORM_ID} from '@angular/core';
import {provideRouter} from '@angular/router';
import {routes} from './app.routes';
import {provideClientHydration} from '@angular/platform-browser';
import {provideHttpClient, withFetch, withInterceptors} from '@angular/common/http';
import {provideAnimations} from '@angular/platform-browser/animations';
import {ConfigService} from './services/configService';
import {DatePipe, isPlatformBrowser} from '@angular/common';
import {httpInterceptor} from './services/http-interceptor';
import {environment} from '../environments/environment';
import {routesPD} from './app.routes.pd';

export const initializeApplication = (ss: ConfigService) => {
  return async () => {
    return ss.initialize();
  };
};

export const appConfig: ApplicationConfig = {
  providers: [
    // {provide: APP_SSR_COOKIES, useValue: ''},
    DatePipe,
    // provideServiceWorker('ngsw-config.json'),
    // Do not use withFetch() on server, because it doesn't support cookies. (node-fetch, issue)
    //provideHttpClient(...(isPlatformBrowser(PLATFORM_ID) ? [withFetch()] : []), withInterceptors([httpInterceptor])),
    provideHttpClient(...(typeof window === 'undefined' ? [] : [withFetch()]), withInterceptors([httpInterceptor])),
    provideClientHydration(),
    provideAnimations(),
    provideRouter(
      environment.pd ? routesPD : routes,
      // withDebugTracing()
      // withPreloading(PreloadAllModules)
    ),
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApplication,
      multi: true,
      deps: [ConfigService],
    }
  ]
};
