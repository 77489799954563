import {CanActivateFn, ResolveFn, Router} from '@angular/router';
import {ISidebarItem} from '../../../oex-ui-kit/components/sidebar/sidebar.component';
import {inject} from '@angular/core';
import {PartnersDirectoryService} from '../partnersDirectory';
import {of} from 'rxjs/internal/observable/of';
import {AuthService} from '../authService';

export const adminSidebarItems: ResolveFn<ISidebarItem[]> = () => {
  const pd = inject(PartnersDirectoryService);
  const items: ISidebarItem[] = [
    {label: 'Featured apps', url: 'featuredapps'},
    {label: 'Certificates', url: 'certificates'},
    {label: 'Newsletter', url: 'newsletter'},
    {label: 'Benefits reports', url: 'benefits-reports'},
    {label: 'Marketing tools', url: 'marketing-tools'},
    {
      label: 'Partner benefits', children: [
        {label: 'InterSystems software', url: 'benefits/intersystems'},
        {label: 'Training', url: 'benefits/training'},
        {label: 'Support', url: 'benefits/support'},
        {label: 'Marketing ', url: 'benefits/marketing'},
      ]
    },
    {
      label: 'App Rewards', children: [
        {label: 'Nominations', url: 'rewards/nominations'},
        {label: 'Rewards', url: 'rewards/list'}
      ]
    },
    // {label: 'Digital services', url: 'digitalservices'},
    ...(pd.enabled ? [{label: 'Health Innovation Hub Inquires', url: 'inquiries'}] : []),
    {label: 'Reviews', url: 'reviews'},
    {label: 'Feature alerts', url: 'feature-alerts'},
    {label: 'Companies', url: 'companies'},
    {label: 'Solutions', url: 'solutions'},
    {label: 'Releases', url: 'releases'},
    {label: 'App quality management', url: 'app-quality'}
  ];
  return of(items);
}

export const guardAdminPanel: CanActivateFn = () => {
  const auth = inject(AuthService)
  const router = inject(Router);
  if (!auth.isLogged || !auth.isAdmin) {
    return router.navigateByUrl('');

  }
  return true;
}
